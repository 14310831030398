'use client'
import { getLatLngByIPOrGeo } from "@/lib/utils";
import { useLocationStore } from "@/store/location";
import { useRouter } from "next/navigation";
import { ReactNode, Suspense, useEffect } from "react";

type Props = {
  children: ReactNode
}
export const SearchParamsWrapper = ({ children }: Props) => {
  const router = useRouter()
  const params = new URLSearchParams();
  useEffect(() => {
    const fetchCity = async () => {
      try {
        const location = await getLatLngByIPOrGeo() as any
      if (location?.lat !== undefined) {
        const setLocation = useLocationStore.getState().setLocation
        setLocation(location)
        params.set("latitude", location.lat?.toString());
        params.set("longitude", location.lng?.toString());
        if(location.address) params.set("address", location.address);
        router.replace(`?${params.toString()}`, undefined);
      }
      } catch (error) {
        console.error(error)
      }
    }
    fetchCity()
  }, [])

  return (
    <Suspense fallback={<Spinner />}>
      {children}
    </Suspense>
  )
}

const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div
        className="w-14 h-14 rounded-full bg-[conic-gradient(#0000_10%,#474bff)] animate-spin"
        style={{
          WebkitMask: "radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0)",
          mask: "radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0)",
        }}
        />
    </div>
  );
};
